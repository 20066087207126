export const getReactPlayerConfig = () => {
  return {
    youtube: {
      playerVars: {
        origin: typeof window === "object" ? `${window.location.href}` : null,
        showinfo: 0,
        playsinline: 1,
        rel: 0,
      },
    },
    vimeo: {
      playerOptions: {
        byline: false,
        color: "#ffffff",
        transparent: true,
      },
    },
    file: {
      attributes: {
        crossOrigin: "anonymous",
      },
    },
    wistia: {
      options: {
        playerColor: "000038",
        controlsVisibleOnLoad: false,
        copyLinkAndThumbnailEnabled: false,
        playButton: false,
        wmode: "transparent",
        fitStrategy: "cover",
        chromeless: true,
        controls: false,
      },
    },
  };
};
