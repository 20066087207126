import clsx from "clsx";

const QuoteMark = () => {
  return (
    <svg
      viewBox="0 0 37 27"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx("fill-pink")}
    >
      <path d="M23.1067 16.863C23.1067 11.9947 24.2929 8.18327 26.6653 5.42883C29.0703 2.67438 32.4339 0.864769 36.7563 0V4.99644C33.4739 5.98932 31.2639 7.78292 30.1265 10.3772C29.4765 11.7544 29.2165 13.0836 29.3465 14.3648H37V27H23.1067V16.863ZM0 16.863C0 12.0587 1.15371 8.26335 3.46113 5.47687C5.80105 2.69039 9.19719 0.864769 13.6495 0V4.99644C10.3347 6.02135 8.10848 7.78292 6.97101 10.2811C6.38603 11.5943 6.14229 12.9555 6.23979 14.3648H13.8933V27H0V16.863Z" />
    </svg>
  );
};

export default QuoteMark;
