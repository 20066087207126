import clsx from "clsx";

const PlayButton = ({ onClick }) => {
  return (
    <button
      className={clsx(
        "PlayButton",
        "absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform",
        "h-[60px] w-[60px] lg:h-[90px] lg:w-[90px] xl:h-[120px] xl:w-[120px]"
      )}
      aria-label="Play"
      onClick={onClick}
    >
      <svg viewBox="0 0 92 92" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="46" cy="46" r="46" fill="#FF004F" />
        <path
          d="M31.8408 45.7305V21.5137L70.0362 45.7305L31.8408 69.9472V45.7305Z"
          fill="white"
        />
      </svg>
    </button>
  );
};

export default PlayButton;
