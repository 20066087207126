import Heading from "@/components/Heading";
import { widont } from "@/lib/widont";
import clsx from "clsx";
import React from "react";
import ReactHtmlParser from "react-html-parser";
import { useInView } from "react-intersection-observer";

type TextProps = {
  text: string;
  theme?: "light" | "dark";
  alignment?: "left" | "center";
  heading?: string;
  headingPosition?: "inline" | "offsetLeft";
};

const BodyText = ({
  text,
  heading,
  headingPosition = "inline",
  alignment = "left",
}: TextProps): JSX.Element => {
  const { ref, inView } = useInView();

  return (
    <div
      className={clsx(
        "BodyText",
        "transform transition duration-500 ease-out",
        {
          "opacity-0": !inView,
          "opacity-100": inView,
        }
      )}
      ref={ref}
    >
      <div
        className={clsx("BodyText__content", "mx-auto w-full max-w-prose", {
          "flex flex-col lg:flex-row": headingPosition === "offsetLeft",
        })}
      >
        {heading && (
          <div
            className={clsx("BodyText__headingWrapper", "mb-4 max-w-fit", {
              "lg:w-1/2 lg:pr-16 xl:w-1/3 ": headingPosition === "offsetLeft",
            })}
          >
            <div
              className={clsx("BodyText__heading", "text-2xl  font-bold", {
                "lg:text-right": headingPosition === "offsetLeft",
              })}
            >
              {widont(heading)}
            </div>
          </div>
        )}
        <div
          className={clsx("BodyText__text", "w-prose prose lg:prose-xl", {
            "text-center": alignment === "center",
            "lg:w-1/2 xl:w-2/3": !!heading && headingPosition === "offsetLeft",
          })}
        >
          {ReactHtmlParser(text)}
        </div>
      </div>
    </div>
  );
};

export default BodyText;
