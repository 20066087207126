import clsx from "clsx";

const Spacer = ({ size = "md" }) => {
  return (
    <div
      className={clsx("Spacer", "block", `Spacer--${size}`, {
        "h-1 md:h-2 xl:h-4": size === "xs",
        "h-4 md:h-6 xl:h-8": size === "sm",
        "h-8 md:h-12 xl:h-20": size === "md",
        "h-16 md:h-24 xl:h-32": size === "lg",
        "h-32 md:h-48 xl:h-64": size === "xl",
      })}
    />
  );
};

export default Spacer;
