import Target from "@/components/Target";
import clsx from "clsx";
import Image from "next/legacy/image";

const SingleImage = ({ image, mobileImage, caption, target, size = "lg" }) => {
  if (!image) return null;

  const targetMobileImage = mobileImage || image;

  return (
    <div className={clsx("container")}>
      <Target
        className={clsx("mx-auto w-full", {
          "max-w-sm": size === "2xs",
          "max-w-lg": size === "xs",
          "max-w-xl": size === "sm",
          "max-w-3xl": size === "md",
          "max-w-4xl": size === "lg",
          "": size === "xl",
        })}
        target={target}
      >
        <div className={clsx("hidden overflow-hidden rounded-3xl md:block")}>
          <Image
            src={image.url}
            width={image.width}
            height={image.height}
            alt={image.title}
            layout="responsive"
          />
        </div>
        <div className={clsx("block overflow-hidden rounded-3xl md:hidden")}>
          <Image
            src={targetMobileImage.url}
            width={targetMobileImage.width}
            height={targetMobileImage.height}
            alt={targetMobileImage.title}
            layout="responsive"
          />
        </div>
        {!!caption && (
          <div
            className={clsx(
              "border-b border-b-cool-gray py-4 text-sm text-black/50",
            )}
            dangerouslySetInnerHTML={{ __html: caption }}
          ></div>
        )}
      </Target>
    </div>
  );
};

export default SingleImage;
