import Logo from "@/components/Logo";
import Quotation from "@/components/Quotation";
import clsx from "clsx";
import { useState } from "react";
import { Controller } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

const Testimonials = ({ testimonials }) => {
  const [swiper, setSwiper] = useState(null);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  const [activeTestimonial, setActiveTestimonial] = useState(testimonials[0]);

  return (
    <div className={clsx("Testimonials")}>
      <div className={clsx("Testimonials__content")}>
        <div className={clsx("Testimonials__main")}>
          <Swiper
            a11y={{
              prevSlideMessage: "Previous slide",
              nextSlideMessage: "Next slide",
            }}
            modules={[Controller]}
            slideToClickedSlide
            autoHeight
            onSwiper={setSwiper}
            onSlideChange={(swiper) => {
              setActiveTestimonial(testimonials[swiper.realIndex]);
              thumbsSwiper.slideTo(swiper.realIndex);
            }}
            spaceBetween={32}
            slidesPerView={1.15}
            slidesOffsetBefore={32}
            slidesOffsetAfter={16}
            breakpoints={{
              768: {
                slidesPerView: 1.15,
                slidesOffsetAfter: 64,
              },
              1024: {
                slidesOffsetBefore: 64,
                slidesOffsetAfter: 64,
              },
              1280: {
                slidesPerView: 1.25,
                slidesOffsetBefore: 128,
                slidesOffsetAfter: 128,
              },
            }}
          >
            {testimonials.map((testimonial) => {
              const logo = testimonial.logo[0];

              const isActive = activeTestimonial?.id === testimonial.id;

              return (
                <SwiperSlide key={testimonial.id}>
                  <div
                    className={clsx(
                      "Testimonials__testimonial",
                      "bg-white",
                      "mx-auto",
                      "transition-opacity",
                      "px-8 lg:px-16",
                      { "opacity-10": !isActive, "opacity-100": isActive },
                    )}
                  >
                    <div
                      className={clsx(
                        "Testimonial__body",
                        "mx-auto max-w-screen-2xl",
                        "relative",
                      )}
                    >
                      <Quotation
                        quotation={testimonial.quotation}
                        attribution={testimonial.attribution}
                      />
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
        <div className={clsx("Testimonials__navigation")}>
          <Swiper
            watchSlidesProgress
            slideToClickedSlide
            modules={[Controller]}
            onSwiper={setThumbsSwiper}
            threshold={10}
            onClick={(ev) => {
              setActiveTestimonial(testimonials[ev.clickedIndex]);
              swiper.slideTo(ev.clickedIndex);
              thumbsSwiper.slideTo(ev.clickedIndex);
            }}
            className={clsx("Testimonials__logos", "py-8 md:py-12 lg:py-16")}
            slidesPerView={1.75}
            spaceBetween={20}
            slidesOffsetBefore={64}
            slidesOffsetAfter={64}
            breakpoints={{
              768: {
                slidesPerView: 4,
              },
              1024: {
                slidesPerView: 4.5,
                slidesOffsetBefore: 128,
                slidesOffsetAfter: 128,
              },
              1280: {
                slidesPerView: 6,
                slidesOffsetBefore: 192,
                slidesOffsetAfter: 192,
              },
            }}
          >
            {testimonials.map((testimonial) => {
              const logo = testimonial.logo[0];
              const logoBlur = testimonial.logoBlur[0];
              const isCurrent = activeTestimonial?.id === testimonial.id;

              return (
                <SwiperSlide
                  key={testimonial.id}
                  className={clsx(
                    "relative",
                    "cursor-pointer",
                    "hover:opacity-100",
                    "transition-opacity",
                    {
                      "opacity-100": isCurrent,
                      "opacity-20": !isCurrent,
                    },
                  )}
                >
                  <div
                    className={clsx(
                      "bg-white",
                      "shadow-lg hover:shadow-xl",
                      "transition-all",
                      "rounded-xl",
                      "border-2",
                      {
                        "border-transparent": !isCurrent,
                        "border-navy": isCurrent,
                      },
                      "px-8 py-4",
                    )}
                  >
                    <div className="relative aspect-16/9">
                      <div className="flex h-full w-full items-center justify-center">
                        <Logo logo={logo} />
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
