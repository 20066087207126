import Target from "@/components/Target";
import TextButton from "@/components/TextButton";
import clsx from "clsx";
import ReactHtmlParser from "react-html-parser";

const Header = ({
  eyebrow,
  heading,
  subHeading,
  headingSize,
  headingTag,
  target,
}) => {
  return (
    <div className={clsx("Header", "mx-auto", "px-8 lg:px-16 2xl:px-32")}>
      <div className={clsx("Header__content", "flex flex-col lg:flex-row")}>
        <div
          className={clsx("Header__main", "lg:w-1/2", "mb-8 lg:mb-0 lg:pr-16")}
        >
          <h2
            className={clsx(
              "Header__heading",
              "markdown text-4xl font-bold tracking-tight",
              {
                "text-lg sm:text-xl md:text-2xl lg:text-3xl":
                  headingSize === "sm",
                "text-xl sm:text-2xl md:text-3xl lg:text-4xl":
                  headingSize === "md",
                "text-3xl sm:text-4xl md:text-5xl lg:text-6xl":
                  headingSize === "lg",
                "text-4xl sm:text-5xl md:text-6xl lg:text-7xl":
                  headingSize === "xl",
              },
            )}
          >
            <Target target={target}>{heading}</Target>
          </h2>
          {!!target && !!target.url && (
            <div className={clsx("Header__textButton", "mt-4 lg:mt-6")}>
              <TextButton target={target} />
            </div>
          )}
        </div>

        <div className={clsx("Header__sidebar", "lg:w-1/2")}>
          <div
            className={clsx(
              "Header__subHeading",
              "lg:ml-auto",
              "link-children text-xl tracking-tight",
              "max-w-lg lg:max-w-2xl",
            )}
          >
            {ReactHtmlParser(subHeading)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
