import clsx from "clsx";
import chunk from "lodash/chunk";
import Image from "next/image";

const ImageGrid = ({ images }) => {
  const rows = chunk(images, Math.ceil(images.length / 2));

  return (
    <div className={clsx("space-y-6 overflow-hidden")}>
      {rows.map((row, index) => {
        const isOdd = index % 2 === 0;

        return (
          <div
            key={index}
            className={clsx("flex w-[112.5%] gap-6", {
              "-translate-x-32": isOdd,
              "translate-x-6": !isOdd,
            })}
          >
            {row.map((image) => {
              return (
                <div
                  key={image.id}
                  className={clsx("w-1/4 overflow-hidden rounded-2xl")}
                >
                  <div className={clsx("relative aspect-[16/11]")}>
                    <Image
                      src={image.url}
                      alt={image.title}
                      fill
                      className={clsx("object-fit")}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default ImageGrid;
