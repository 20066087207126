import clsx from "clsx";
import Image from "next/image";

const FullWidthImage = ({ image }) => {
  return (
    <div className={clsx("relative aspect-[24/10] overflow-hidden")}>
      <Image
        src={image.url}
        alt={image.title}
        fill
        className={clsx("object-cover")}
      />
    </div>
  );
};

export default FullWidthImage;
