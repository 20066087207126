import clsx from "clsx";
import Image from "next/image";

const IconGrid = ({ items = [], columns = "4" }) => {
  return (
    <div className={clsx("container")}>
      <div
        className={clsx("grid gap-8", {
          "grid-cols-12": columns === "3" || columns === "4",
          "grid-cols-10": columns === "5",
        })}
      >
        {items.map((item) => {
          const icon = item.icon?.[0];

          return (
            <div
              key={item.id}
              className={clsx(
                "flex flex-col items-center justify-center @container",
                {
                  "col-span-12 md:col-span-6 lg:col-span-4": columns === "3",
                  "col-span-12 md:col-span-6 lg:col-span-3": columns === "4",
                  "col-span-10 md:col-span-5 lg:col-span-2": columns === "5",
                },
              )}
            >
              <div
                className={clsx(
                  "relative mx-auto aspect-square w-28 md:w-32 lg:w-40",
                )}
              >
                {!!icon && (
                  <Image
                    src={icon.url}
                    alt={icon.title}
                    fill
                    className={clsx("object-fit")}
                  />
                )}
              </div>
              <div
                className={clsx(
                  "mx-auto w-full max-w-md text-center text-xl font-bold @sm:text-xl md:text-2xl lg:text-2xl",
                )}
              >
                {item.caption}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default IconGrid;
