import { useEffect } from 'react';

const useRemoveTextGap = (elementRef) => {
  useEffect(() => {
    // Ensure this part of the code runs only on the client side
    const ctx = document.createElement('canvas').getContext('2d');
    const cache = new Map();

    const isColumnEmpty = (pixels, width, height, x) => {
      for (let y = 0; y < height; ++y) {
        const offset = y * width + x;
        if (pixels[offset]) {
          return false;
        }
      }
      return true;
    };

    const getGap = (element) => {
      const style = window.getComputedStyle(element);
      const left =
          style.textAlign === 'left' ||
          (style.textAlign === 'start' && style.direction === 'ltr') ||
          (style.textAlign === 'end' && style.direction === 'rtl');
      const right =
          style.textAlign === 'right' ||
          (style.textAlign === 'start' && style.direction === 'rtl') ||
          (style.textAlign === 'end' && style.direction === 'ltr');

      const font = `${style.fontWeight} ${style.fontSize} ${style.fontFamily}`;
      const text = element.textContent;
      const width = 128;
      const height = element.clientHeight;
      const char = right
          ? text.substring(text.length - 1, text.length)
          : text.substring(0, 1);
      const key = `${font} ${window.devicePixelRatio} ${height} ${style.textAlign} + ${char}`;
      let gap = cache.get(key);
      if (gap === undefined) {
        gap = 0;
        if (height && (left || right)) {
          const dpr = window.devicePixelRatio;
          ctx.canvas.width = width * dpr;
          ctx.canvas.height = height * dpr;
          ctx.scale(dpr, dpr);
          ctx.font = font;
          ctx.textAlign = right ? 'right' : 'left';

          ctx.fillText(text, right ? width - 1 : 0, height);

          const imgData = ctx.getImageData(0, 0, ctx.canvas.width, ctx.canvas.height);
          const pixels = new Uint32Array(imgData.data.buffer);

          const delta = right ? -1 : 1;
          const start = right ? imgData.width - 1 : 0;
          const end = right ? -1 : imgData.width;

          let x = start;
          for (; x !== end; x += delta) {
            if (!isColumnEmpty(pixels, imgData.width, imgData.height, x)) {
              break;
            }
          }

          gap = (start - x) * delta / dpr;
        }
        cache.set(key, gap);
      }

      const margin = `${gap}px`;
      return {
        gap,
        styles: [
          ['marginLeft', left ? margin : '0'],
          ['marginRight', right ? margin : '0'],
        ],
      };
    };

    const removeGap = (element) => {
      const {styles} = getGap(element);
      for (const [prop, value] of styles) {
        element.style[prop] = value;
      }
    };

    // Apply the gap removal
    if (elementRef.current) {
      removeGap(elementRef.current);
    }
  }, [elementRef]);
};

export default useRemoveTextGap;
