import Target from "@/components/Target";
import VideoEmbed from "@/components/VideoEmbed";
import clsx from "clsx";
import Image from "next/legacy/image";
import { ArrowRight } from "react-feather";
import ReactHtmlParser from "react-html-parser";

const Card = ({
  attribution,
  backgroundImage,
  eyebrow,
  figure,
  image,
  label,
  quotation,
  target,
  text,
  secondaryText,
  videoUrl,
  videoFile,
  width = "half",
  backgroundColor = "white",
  textColor = "navy",
  border = "none",
  aspectRatio = "16/11",
  bodyText,
  logo = null,
}) => {
  const gradientBackground = backgroundColor === "navyGradient";

  return (
    <Target
      target={target}
      className={clsx(
        "Card",
        "group relative  z-0 block h-full h-full transform-gpu overflow-hidden rounded-2xl shadow-xl ring-4 transition-all @container",
        {
          "hover:shadow-2xl": !!target && !!target.url,
          "bg-white": backgroundColor === "white",
          "bg-navy": backgroundColor === "navy",
          "bg-pink": backgroundColor === "pink",
          "bg-transparent": backgroundColor === "transparent",
          "text-navy": textColor === "navy",
          "text-white": textColor === "white",
          "text-pink": textColor === "pink",
          "text-gradient-to-r": textColor === "gradient",
          "ring-pink": border === "pink",
          "ring-transparent": !border || border === "none",
          "ring-white": border === "white",
          "aspect-[16/11]": aspectRatio === "16/11",
          "aspect-[16/9]": aspectRatio === "16/9",
          "aspect-[16/6]": aspectRatio === "16/6",
          "aspect-[4/3]": aspectRatio === "4/3",
          "aspect-[4/5]": aspectRatio === "4/5",
          "aspect-none md:aspect-[1/1]": aspectRatio === "1/1",
          "aspect-none": aspectRatio === "none",
        },
      )}
    >
      <div
        className={clsx("Card__content", "relative flex h-full flex-col", {
          "bg-gradient-to-r from-pink to-magenta": gradientBackground,
        })}
      >
        {(!!videoUrl || !!videoFile) && (
          <div className={clsx("Card__videoEmbedContainer")}>
            <div className={clsx("Card__videoEmbed", "absolute inset-0 z-10")}>
              <VideoEmbed
                url={videoUrl}
                file={videoFile}
                autoPlay
                image={image}
                layout="fill"
              />
            </div>
            {!!image && (
              <div className={clsx("Card__videoImage", "relative z-0")}>
                <Image
                  src={image.url}
                  width={image.width}
                  height={image.height}
                  alt={image.title}
                  layout="responsive"
                />
              </div>
            )}
          </div>
        )}

        <div className={clsx("relative z-10", "h-full", "flex flex-col")}>
          {!!logo && (
            <div className={clsx("w-1/2 p-8 lg:p-10")}>
              <div className={clsx("relative aspect-[16/3]")}>
                <Image
                  src={logo.url}
                  alt={logo.title}
                  fill
                  className={clsx("object-contain object-left")}
                />
              </div>
            </div>
          )}

          {(!!eyebrow || !!figure || !!label) && (
            <div className={clsx("space-y-2 p-8 lg:p-10")}>
              {!!eyebrow && (
                <div
                  className={clsx(
                    "Card__eyebrow",
                    "markdown relative text-lg font-bold tracking-tight lg:text-xl",
                  )}
                >
                  <span
                    className={clsx({
                      "text-gradient-to-r":
                        !!figure && !!label && !gradientBackground,
                    })}
                  >
                    {ReactHtmlParser(eyebrow)}
                  </span>
                </div>
              )}

              {(!!figure || !!label) && (
                <div className={clsx("Card__statistic", "", {})}>
                  <div
                    className={clsx(
                      "Card__figure",
                      "font-bold leading-none tracking-tight",
                      "text-5xl @xs:text-5xl @sm:text-6xl @md:text-7xl @lg:text-8xl @xl:text-9xl",
                      {},
                    )}
                  >
                    {figure}
                  </div>
                  <div
                    className={clsx(
                      "Card__label",
                      "text-2xl font-bold tracking-tight @sm:text-3xl @md:text-3xl @lg:text-4xl",
                      {},
                    )}
                  >
                    {label}
                  </div>
                </div>
              )}
            </div>
          )}

          {(!!text || !!secondaryText || !!bodyText) && (
            <div
              className={clsx(
                "Card__text",
                "relative z-10 max-w-5xl space-y-2 px-8 py-8 lg:px-10 lg:py-10",
              )}
            >
              {!!text && (
                <div
                  className={clsx(
                    "font-bold tracking-tight lg:@md:leading-none",
                    "text-3xl lg:@sm:text-4xl lg:@md:text-4xl",
                  )}
                >
                  {text}
                </div>
              )}

              {!!secondaryText && (
                <div
                  className={clsx(
                    "font-bold tracking-tight",
                    "text-2xl lg:@xs:text-3xl lg:@sm:text-3xl",
                  )}
                >
                  {secondaryText}
                </div>
              )}

              {!!bodyText && (
                <div
                  className={clsx(
                    "text-lg font-bold lg:@xs:text-xl lg:@sm:text-2xl",
                  )}
                  dangerouslySetInnerHTML={{ __html: bodyText }}
                ></div>
              )}
            </div>
          )}

          {!!quotation && (
            <div
              className={clsx(
                "Card__quotation",
                "mt-auto space-y-4 p-10 text-4xl font-bold tracking-tight 2xl:text-5xl",
                {
                  "text-white": backgroundColor === "navy",
                },
              )}
            >
              <div className={clsx("Card__quoteMark", "flex justify-start")}>
                <svg
                  width="37"
                  height="27"
                  viewBox="0 0 37 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className={clsx("")}
                >
                  <path
                    d="M23.1067 16.863C23.1067 11.9947 24.2929 8.18327 26.6653 5.42883C29.0703 2.67438 32.4339 0.864769 36.7563 0V4.99644C33.4739 5.98932 31.2639 7.78292 30.1265 10.3772C29.4765 11.7544 29.2165 13.0836 29.3465 14.3648H37V27H23.1067V16.863ZM0 16.863C0 12.0587 1.15371 8.26335 3.46113 5.47687C5.80105 2.69039 9.19719 0.864769 13.6495 0V4.99644C10.3347 6.02135 8.10848 7.78292 6.97101 10.2811C6.38603 11.5943 6.14229 12.9555 6.23979 14.3648H13.8933V27H0V16.863Z"
                    fill="currentColor"
                  />
                </svg>
              </div>
              <div
                className={clsx(
                  "Card__quotation",
                  "mb-7.5 markdown inline-flex max-w-5xl text-3xl font-bold tracking-tight",
                )}
              >
                {ReactHtmlParser(quotation)}
              </div>
              <div
                className={clsx("text-3xl font-bold text-pink")}
                dangerouslySetInnerHTML={{ __html: attribution }}
              ></div>
            </div>
          )}
        </div>
        {!!image && !videoUrl && !videoFile && (
          <div className={clsx("Card__imageWrapper", "")}>
            {width === "full" && (
              <Image
                src={image.url}
                width={image.width}
                height={image.height}
                alt={image.title}
              />
            )}
            {width !== "full" && (
              <Image
                src={image.url}
                alt={image.title}
                fill
                className={clsx("object-cover")}
              />
            )}
          </div>
        )}

        {!!target?.url && (
          <div
            className={clsx(
              "Card__backgroundImageWrapper",
              "relative z-10 px-8 pb-8 lg:px-10 lg:pb-10",
              "transition ease-out group-hover:translate-x-2",
            )}
          >
            <ArrowRight className={clsx("h-12 w-12 stroke-[1]")} />
          </div>
        )}

        {!!backgroundImage && (
          <div
            className={clsx(
              "Card__backgroundImageWrapper",
              "absolute inset-0 z-0",
            )}
          >
            <div
              className={clsx(
                "Card__backgroundImageOverlay",
                "pointer-events-none absolute inset-0 z-10 bg-black opacity-30",
              )}
            ></div>
            <Image
              src={backgroundImage.url}
              alt={backgroundImage.title}
              layout="fill"
              className={clsx(
                "Card__backgroundImage",
                "origin-center scale-100 transform-gpu object-cover transition-all duration-1000 ease-out group-hover:scale-105",
              )}
            />
          </div>
        )}
      </div>
    </Target>
  );
};

export default Card;
