import PlayButton from "@/components/PlayButton";
import { getReactPlayerConfig } from "@/lib/getReactPlayerConfig";
import clsx from "clsx";
import dynamic from "next/dynamic";
import Image from "next/legacy/image";
import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";

const ReactPlayer = dynamic(() => import("react-player/lazy"), { ssr: false });

const VideoEmbed = ({
  url,
  file,
  image,
  autoPlay = false,
  layout = "intrinsic",
  fullWidthOnMobile = true,
  width = "container",
}) => {
  const [isReady, setIsReady] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);

  const { ref, inView } = useInView();

  useEffect(() => {
    if (autoPlay && inView) setIsPlaying(true);
    if (autoPlay && !inView) setIsPlaying(false);
  }, [autoPlay, inView]);

  const targetUrl = !!file ? file.url : url;

  return (
    <div
      className={clsx("VideoEmbed", "transform-gpu", {
        "relative aspect-16/9": layout === "intrinsic",
        "absolute inset-0": layout === "fill",
        "overflow-hidden rounded-xl": width !== "full" && !fullWidthOnMobile,
        "md:overflow-hidden md:rounded-xl":
          width !== "full" && fullWidthOnMobile,
        "mx-auto max-w-screen-3xl": width === "container",
        "w-full": width === "full",
      })}
      ref={ref}
    >
      <div className={clsx("VideoEmbed__playerContainer", "bg-black")}>
        <div
          className={clsx("VideoEmbed__playerWrapper", "absolute inset-0 z-10")}
        >
          <ReactPlayer
            url={targetUrl}
            playsinline
            loop={autoPlay}
            width="100%"
            height="100%"
            muted={autoPlay}
            controls={!autoPlay}
            playing={isReady && isPlaying}
            className={clsx("VideoEmbed__player", {
              "child-video-object-cover": layout === "fill",
            })}
            onReady={() => setIsReady(true)}
            onPlay={() => setIsPlaying(true)}
            onPause={() => setIsPlaying(false)}
            config={getReactPlayerConfig()}
          />
        </div>
        {!isPlaying && <PlayButton onClick={() => setIsPlaying(true)} />}
      </div>
      {!!image && (
        <div
          className={clsx(
            "VideoEmbed__videoImage",
            "absolute inset-0 z-0",
            "transition-opacity delay-500 duration-500",
            {
              "opacity-0": isReady && isPlaying,
            },
          )}
        >
          <Image
            src={image.url}
            alt={image.title}
            layout="fill"
            objectFit="cover"
          />
        </div>
      )}
    </div>
  );
};

export default VideoEmbed;
