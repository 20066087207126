import clsx from "clsx";
import ReactHtmlParser from "react-html-parser";

const Eyebrow = ({ eyebrow }) => {
  return (
    <div
      className={clsx(
        "Eyebrow",
        "markdown text-xl font-bold tracking-tight lg:text-2xl 2xl:text-3xl 3xl:text-4xl"
      )}
    >
      {ReactHtmlParser(eyebrow)}
    </div>
  );
};

export default Eyebrow;
