import { getHref } from "lib/routes";
import Link from "next/link";
import { useRouter } from "next/router";

const Target = ({
  className = "",
  target,
  children,
  style = {},
  onMouseEnter = () => null,
  onMouseLeave = () => null,
}): JSX.Element => {
  const router = useRouter();

  function isExternalUrl(targetUrl) {
    // Check if the window exists
    if (typeof window === "undefined") return false;

    const currentUrl = `${window.location.protocol}//${window.location.host}${router.asPath}`;
    return (
      new URL(currentUrl).hostname !== new URL(targetUrl, currentUrl).hostname
    );
  }

  const returnElement = () => {
    return (
      <div className={className} style={style}>
        {children}
      </div>
    );
  };

  if (!target || target.type === "") {
    return returnElement();
  }

  if (target.type === "entry" && !!target.element) {
    const href = getHref(target.element);

    if (!href) return returnElement();

    return (
      <Link
        href={href}
        className={className}
        style={style}
        draggable={false}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {children}
      </Link>
    );
  }

  if (target.type === "asset" && !!target.element) {
    return (
      <a
        href={target.url}
        className={className}
        draggable={false}
        download
        target="_blank"
        rel="noreferrer"
        style={style}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {children}
      </a>
    );
  }

  if (target.type === "url") {
    // Determine whether the url is external or internal
    const isExternal = isExternalUrl(target.url);

    return (
      <a
        href={target.url}
        className={className}
        target={isExternal ? "_blank" : "_self"}
        rel="noreferrer"
        draggable={false}
        style={style}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {children}
      </a>
    );
  }

  if (target.type === "email") {
    return (
      <a
        href={target.url}
        className={className}
        rel="noreferrer"
        draggable={false}
        style={style}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {children}
      </a>
    );
  }

  if (target.type === "custom") {
    return (
      <a
        href={target.url}
        className={className}
        draggable={false}
        style={style}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {children}
      </a>
    );
  }

  return returnElement();
};

export default Target;
