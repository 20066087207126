import Button from "@/components/Button";
import Eyebrow from "@/components/Eyebrow";
import Quotation from "@/components/Quotation";
import VideoEmbed from "@/components/VideoEmbed";
import clsx from "clsx";
import Image from "next/legacy/image";
import ReactHtmlParser from "react-html-parser";

const SideBySide = ({
  image,
  mobileImage,
  mediaPosition = "left",
  videoUrl,
  text,
  secondaryText,
  quotation,
  eyebrow,
  target,
  textSize = "md",
  mediaWidth = "md",
}) => {
  return (
    <div
      className={clsx(
        "SideBySide",
        "mx-auto max-w-screen-3xl",
        "px-8 lg:px-16",
      )}
    >
      <div
        className={clsx(
          "SideBySide__content",
          "flex flex-col items-center gap-x-16 md:flex-row",
        )}
      >
        <div
          className={clsx("SideBySide__media", "mb-8 md:mb-0", {
            "w-full xl:w-2/5": mediaWidth === "sm",
            "w-full xl:w-1/2": mediaWidth === "md",
            "w-full xl:w-3/5": mediaWidth === "lg",
            "order-1 md:order-2": mediaPosition === "right",
            "order-1 md:order-1": mediaPosition === "left",
          })}
        >
          {!!image && (
            <div
              className={clsx(
                "SideBySide__imageWrapper",
                "overflow-hidden rounded-xl",
              )}
            >
              <Image
                src={image.url}
                width={image.width}
                height={image.height}
                alt={image.title}
                layout="responsive"
                className={clsx("SideBySide__image")}
              />
            </div>
          )}
          {!!videoUrl && (
            <div
              className={clsx(
                "SideBySide__videoWrapper",
                "overflow-hidden rounded-xl",
              )}
            >
              <VideoEmbed url={videoUrl} />
            </div>
          )}
        </div>
        <div
          className={clsx("SideBySide__body", {
            "w-full xl:w-3/5": mediaWidth === "sm",
            "w-full xl:w-1/2": mediaWidth === "md",
            "w-full xl:w-2/5": mediaWidth === "lg",
            "order-2 md:order-1": mediaPosition === "right",
            "order-2 md:order-2": mediaPosition === "left",
          })}
        >
          {!!eyebrow && (
            <div className={clsx("SideBySide__eyebrow", "mb-8")}>
              <Eyebrow eyebrow={eyebrow} />
            </div>
          )}
          <div
            className={clsx(
              "SideBySide__text",
              "markdown font-bold tracking-tight",
              {
                "text-xl lg:text-3xl": textSize === "sm",
                "text-3xl lg:text-4xl": textSize === "md",
                "text-4xl lg:text-6xl": textSize === "lg",
              },
            )}
          >
            {ReactHtmlParser(text)}
          </div>
          {!!secondaryText && (
            <div
              className={clsx(
                "SideBySide__secondaryText",
                "bold-children markdown",
                "text-xl",
                "mt-8",
              )}
            >
              {ReactHtmlParser(secondaryText)}
            </div>
          )}
          {!!quotation && (
            <div className={clsx("SideBySide__quotation", "mt-8")}>
              <Quotation quotation={quotation} />
            </div>
          )}
          {!!target && !!target.url && (
            <div className={clsx("SideBySide__button", "mt-8")}>
              <Button target={target} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SideBySide;
