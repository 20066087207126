import EntryThumbnail from "@/components/EntryThumbnail";
import clsx from "clsx";
import { A11y, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

const EntryCarousel = ({ entries }) => {
  return (
    <div className={clsx("EntryCarousel")}>
      <div className={clsx("EntryCarousel__content")}>
        <Swiper
          modules={[Pagination, A11y]}
          pagination
          a11y={{
            prevSlideMessage: "Previous slide",
            nextSlideMessage: "Next slide",
          }}
          slidesPerView={1.3}
          spaceBetween={32}
          slidesOffsetBefore={32}
          slidesOffsetAfter={32}
          className={clsx(
            "EntryCarousel__slides",
            "pb-16 md:pb-20 lg:pb-28",
            "relative",
          )}
          breakpoints={{
            768: {
              slidesOffsetBefore: 32,
              slidesOffsetAfter: 32,
              slidesPerView: 2.5,
            },
            1024: {
              slidesOffsetBefore: 64,
              slidesOffsetAfter: 64,
              slidesPerView: 2.5,
            },
            1280: {
              slidesOffsetBefore: 64,
              slidesOffsetAfter: 64,
              slidesPerView: 4,
            },
          }}
        >
          {entries.map((entry) => {
            const image = !!entry.image ? entry.image[0] : null;

            return (
              <SwiperSlide
                key={entry.id}
                className={clsx("EntryCarousel__slide")}
              >
                <EntryThumbnail
                  image={image}
                  heading={entry.title}
                  summary={entry.summary}
                  target={{ element: entry, type: "entry" }}
                  date={entry.postDate}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
};

export default EntryCarousel;
