import clsx from "clsx";
import Image from "next/legacy/image";
import React from "react";

const Logo = ({ logo }) => {
  return (
    <Image
      src={logo.url}
      layout="fill"
      objectFit="contain"
      className={clsx("Logo")}
      draggable={false}
      alt={logo.title}
    />
  );
};

export default Logo;
