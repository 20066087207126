import clsx from "clsx";
import { A11y, Autoplay, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import Logo from "@/components/Logo";

const LogoCarousel = ({ logos }) => {
  // Duplicate logos to create a loop, but ensure all have unique ids
  const logosWithLoop = [...logos, ...logos].map((logo, index) => {
    return {
      ...logo,
      id: `${logo.id}-${index}`,
    };
  });

  return (
    <div className={clsx("LogoCarousel")}>
      <div className={clsx("LogoCarousel__carousel", "relative")}>
        <Swiper
          modules={[A11y, Autoplay, Navigation]}
          loop
          spaceBetween={32}
          centeredSlides
          slidesPerView={"auto"}
          threshold={10}
          navigation
          slideToClickedSlide={false}
          className={clsx("LogoCarousel__slides", "py-8 md:py-12 lg:py-16")}
        >
          {[...logosWithLoop, ...logosWithLoop].map((logo, index) => {
            return (
              <SwiperSlide
                key={`${logo.id}-${index}`}
                className={clsx("LogoCarousel__slide", "!w-80 lg:!w-72")}
              >
                <div
                  className={clsx(
                    "LogoCarousel__logoContainer",
                    "bg-white",
                    "shadow-lg lg:shadow-xl",
                    "transition-colors",
                    "rounded-xl",
                    "px-8 py-8",
                  )}
                >
                  <div
                    className={clsx(
                      "LogoCarousel__logoWrapper",
                      "pointer-events-none relative aspect-16/9",
                    )}
                  >
                    <div className="flex h-full w-full items-center justify-center">
                      <Logo logo={logo} />
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
};

export default LogoCarousel;
