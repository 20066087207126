import clsx from "clsx";
import ReactHtmlParser from "react-html-parser";

import Button from "@/components/Button";
import Eyebrow from "@/components/Eyebrow";

const Statement = ({
  eyebrow,
  heading,
  text,
  textTheme = "dark",
  target,
  size = "lg",
  textAlignment = "left",
  cardContainer = false,
}) => {
  return (
    <div className={clsx("Statement", "px-8 lg:px-16")}>
      <div
        className={clsx("Statement__content", "", {
          "mx-auto max-w-5xl": !cardContainer,
          "mx-auto max-w-7xl rounded-t-3xl bg-white p-32 shadow-xl":
            cardContainer,
        })}
      >
        {!!eyebrow && (
          <div
            className={clsx("Statement__eyebrow", "mb-4 lg:mb-6", {
              "text-navy": textTheme === "dark",
              "text-white": textTheme === "light",
              "text-center": textAlignment === "center",
            })}
          >
            <Eyebrow eyebrow={eyebrow} />
          </div>
        )}
        {!!heading && (
          <div
            className={clsx(
              "Statement__text",
              "markdown font-bold tracking-tight",
              {
                "text-lg sm:text-xl md:text-2xl lg:text-3xl": size === "sm",
                "text-xl sm:text-2xl md:text-3xl lg:text-4xl": size === "md",
                "text-3xl sm:text-4xl md:text-5xl lg:text-6xl": size === "lg",
                "text-4xl sm:text-5xl md:text-6xl lg:text-7xl": size === "xl",
                "text-navy": textTheme === "dark",
                "text-white": textTheme === "light",
                "xl:pr-24": textAlignment === "left",
                "mx-auto text-center": textAlignment === "center",
              },
            )}
          >
            {ReactHtmlParser(text)}
          </div>
        )}
        <div
          className={clsx(
            "Statement__text",
            "markdown space-y-6 font-bold tracking-tight",
            {
              "text-lg sm:text-xl md:text-2xl lg:text-3xl": size === "sm",
              "text-xl sm:text-2xl md:text-3xl lg:text-4xl": size === "md",
              "text-3xl sm:text-4xl md:text-5xl lg:text-6xl": size === "lg",
              "text-4xl sm:text-5xl md:text-6xl lg:text-7xl": size === "xl",
              "text-navy": textTheme === "dark",
              "text-white": textTheme === "light",
              "xl:pr-24": textAlignment === "left",
              "mx-auto text-center": textAlignment === "center",
            },
          )}
        >
          {ReactHtmlParser(text)}
        </div>
        {!!target && !!target.url && (
          <div
            className={clsx("SideBySide__button", "mt-12", {
              "flex justify-center": textAlignment === "center",
            })}
          >
            <Button target={target} theme={textTheme} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Statement;
