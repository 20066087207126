import Logo from "@/components/Logo";
import clsx from "clsx";

const LogoGrid = ({ logos, cardContainer = true }) => {
  return (
    <div className={clsx("LogoGrid", "px-8 lg:px-16")}>
      <div className={clsx("LogoGrid__content", "mx-auto max-w-screen-2xl")}>
        <div
          className={clsx("LogoGrid__awards", "", {
            "grid grid-cols-2 gap-4 md:grid-cols-3 xl:grid-cols-4":
              cardContainer,
            "flex flex-wrap justify-center gap-16": !cardContainer,
          })}
        >
          {logos.map((logo) => {
            return (
              <div
                key={logo.id}
                className={clsx({
                  "rounded-xl bg-white px-8 py-8 shadow-xl md:px-20":
                    cardContainer,
                  "w-1/3 md:w-1/4 lg:w-1/5 xl:w-1/6 2xl:w-[14%]":
                    !cardContainer,
                })}
              >
                <div
                  className={clsx("LogoGrid__logoWrapper", "relative", {
                    "aspect-16/9": cardContainer,
                    "aspect-4/3": !cardContainer,
                  })}
                >
                  <div className="flex h-full w-full items-center justify-center">
                    <Logo logo={logo} />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default LogoGrid;
