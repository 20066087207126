import Target from "@/components/Target";
import clsx from "clsx";
import { ArrowRight } from "react-feather";

const Button = ({ target, theme = "dark", fullWidth = false }) => {
  return (
    <Target
      target={target}
      className={clsx(
        "Button",
        "group inline-flex items-center space-x-2 rounded-full px-7 py-4 transition-colors hover:bg-opacity-90",
        {
          "bg-navy": theme === "dark",
          "text-white": theme === "dark",
          "bg-white": theme === "light",
          "text-navy": theme === "light",
          "w-full justify-center": fullWidth,
        }
      )}
    >
      <div className={clsx("Button__label", "text-lg font-bold")}>
        {target?.text}
      </div>
      <div
        className={clsx(
          "Button__icon",
          "transition-transform",
          "group-hover:translate-x-1"
        )}
      >
        <ArrowRight size={18} />
      </div>
    </Target>
  );
};

export default Button;
