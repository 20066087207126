import clsx from "clsx";
import React from "react";
import ReactHtmlParser from "react-html-parser";

const Blockquote = ({ quotation, attribution }) => {
  return (
    <div className={clsx("Blockquote", "container")}>
      <div className={clsx("Blockquote__content", " mx-auto max-w-4xl ")}>
        <blockquote
          className={clsx(
            "Blockquote__quotation",
            "text-2xl font-light md:text-3xl lg:text-4xl "
          )}
        >
          {ReactHtmlParser(quotation)}
        </blockquote>
        {!!attribution && (
          <div
            className={clsx(
              "Blockquote__attribution",
              "mt-4 text-sm text-navy/75 lg:text-base"
            )}
          >
            {" "}
            {attribution}
          </div>
        )}
      </div>
    </div>
  );
};

export default Blockquote;
