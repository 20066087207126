import Target from "@/components/Target";
import clsx from "clsx";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import Image from "next/legacy/image";
import { useInView } from "react-intersection-observer";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

const EntryThumbnail = ({ image, heading, summary, target, date }) => {
  const { ref, inView } = useInView();

  const parsedDate = dayjs(date);

  return (
    <div
      className={clsx(
        "EntryThumbnail",
        "transition-all duration-500 ease-out",
        "flex h-full flex-grow flex-col",
        "bg-white",
        "relative z-0 transform-gpu rounded-2xl",
        "shadow-xl hover:shadow-2xl",
        {
          "opacity-0": !inView,
          "opacity-100": inView,
        },
      )}
      ref={ref}
    >
      {!!image && !!image.url && (
        <Target
          target={target}
          className={clsx("EntryThumbnail__image", "shrink-0 grow-0")}
        >
          <Image
            src={image.url}
            height={image.height}
            width={image.width}
            alt={image.title}
            layout="responsive"
          />
        </Target>
      )}

      <div className={clsx("EntryThumbnail__caption", "relative", "p-8")}>
        {!!date && (
          <div className={clsx("EntryThumbnail__meta", "mb-2")}>
            <div className={clsx("EntryThumbnail__date")}>
              {parsedDate.format("DD.MM.YYYY")}
            </div>
          </div>
        )}
        <Target
          target={target}
          className={clsx(
            "EntryThumbnail__heading",
            "text-xl font-bold tracking-tight",
          )}
        >
          {heading}
        </Target>
        <div
          className={clsx("EntryThumbnail__summary", "max-w-lg", "lg:pr-7.5")}
        >
          {summary}
        </div>
      </div>
    </div>
  );
};

export default EntryThumbnail;
