import AwardsGrid from "@/components/AwardsGrid";
import Blockquote from "@/components/Blockquote";
import BodyText from "@/components/BodyText";
import Button from "@/components/Button";
import CardGrid from "@/components/CardGrid";
import EntryCarousel from "@/components/EntryCarousel";
import FullWidthImage from "@/components/FullWidthImage";
import Header from "@/components/Header";
import Hero from "@/components/Hero";
import HorizontalLine from "@/components/HorizontalLine";
import IconGrid from "@/components/IconGrid";
import ImageGrid from "@/components/ImageGrid";
import LogoCarousel from "@/components/LogoCarousel";
import LogoGrid from "@/components/LogoGrid";
import SideBySide from "@/components/SideBySide";
import SingleImage from "@/components/SingleImage";
import Spacer from "@/components/Spacer";
import Statement from "@/components/Statement";
import Testimonials from "@/components/Testimonials";
import VideoEmbed from "@/components/VideoEmbed";
import clsx from "clsx";
import Image from "next/image";
import React, { ReactNode } from "react";
import { useInView } from "react-intersection-observer";

const Component = ({ section, isFirst }: { section: any }) => {
  let image;
  let mobileImage;

  switch (section.typeHandle) {
    case "bodyText": {
      return (
        <div className="container">
          <BodyText
            text={section.bodyText}
            heading={section.heading}
            headingSize={section.headingSize}
            headingPosition={section.headingPosition}
          />
        </div>
      );
    }

    case "videoEmbed":
      const width = section.videoWidth || "container";

      return (
        <div
          className={clsx("PageSections__videoEmbed", {
            "md:px-8 lg:px-16": width === "container",
          })}
        >
          <VideoEmbed
            url={section.videoUrl}
            autoPlay={section.autoplayVideo}
            image={section.image?.[0]}
            width={width}
          />
        </div>
      );

    case "spacer":
      return (
        <Spacer backgroundColor={section.backgroundColor} size={section.size} />
      );

    case "statement":
      return (
        <Statement
          eyebrow={section.eyebrowHeading}
          text={section.bodyText}
          textTheme={section.textTheme}
          target={section.target}
          size={section.size}
          textAlignment={section.textAlignment}
          cardContainer={section.cardContainer}
        />
      );

    case "sideBySide":
      image = section.image[0];
      mobileImage = section.mobileImage[0];

      return (
        <SideBySide
          eyebrow={section.eyebrowHeading}
          text={section.bodyText}
          secondaryText={section.secondaryBodyText}
          quotation={section.quotation}
          target={section.target}
          image={image}
          mobileImage={mobileImage}
          videoUrl={section.videoUrl}
          textSize={section.textSize}
          mediaWidth={section.mediaWidth}
          mediaPosition={section.mediaPosition}
        />
      );

    case "testimonialsCarousel":
      return <Testimonials testimonials={section.testimonials} />;

    case "singleImage":
      image = section.image[0];

      mobileImage = section.mobileImage[0];

      return (
        <SingleImage
          image={image}
          mobileImage={mobileImage}
          target={section.target}
          caption={section.caption}
          size={section.size}
        />
      );

    case "header":
      return (
        <Header
          eyebrow={section.eyebrowHeading}
          heading={section.heading}
          headingTag={section.headingTag}
          headingSize={section.headingSize}
          subHeading={section.subHeading}
          target={section.target}
        />
      );

    case "blockquote": {
      return (
        <Blockquote
          quotation={section.quotation}
          attribution={section.attribution}
        />
      );
    }

    case "entryCarousel":
      return <EntryCarousel entries={section.carouselEntries}></EntryCarousel>;

    case "cardGrid":
      return (
        <CardGrid
          cards={section.cards}
          aspectRatio={section.cardAspectRatio}
        ></CardGrid>
      );

    case "awardsGrid":
      return <AwardsGrid awards={section.awards} />;

    case "logoGrid":
      return (
        <LogoGrid
          logos={section.logos}
          cardContainer={section.containWithinCard}
        />
      );

    case "logoCarousel":
      return <LogoCarousel logos={section.logos} />;

    case "button":
      return (
        <div
          className={clsx("mx-auto max-w-screen-2xl", "flex justify-center")}
        >
          <Button target={section.target} />
        </div>
      );

    case "gradientBreak":
      return (
        <div
          className={clsx(
            "absolute left-0 right-0 top-0 w-full",
            "h-screen-2x",
            "bg-gradient-to-b from-black opacity-5",
          )}
        ></div>
      );

    case "background":
      const backgroundImage = section.backgroundImage?.[0];

      return (
        <div
          className={clsx("Background", "relative", {
            "bg-navy text-white": section.backgroundColor === "navy",
            "bg-pink text-white": section.backgroundColor === "pink",
            "bg-white text-navy": section.backgroundColor === "white",
            "bg-transparent text-navy":
              section.backgroundColor === "transparent",
            "bg-white-dark text-navy": section.backgroundColor === "lightGray",
            "bg-gradient-to-b from-navy via-navy to-blue text-white":
              section.backgroundColor === "navyGradient",
            "bg-gradient-to-b from-white-dark via-white-dark to-white text-navy":
              section.backgroundColor === "lightGrayGradient",
          })}
        >
          {!!backgroundImage && (
            <div
              className={clsx(
                "Background__backgroundImage",
                "absolute inset-0 z-10",
              )}
            >
              <Image
                src={backgroundImage.url}
                fill
                alt={backgroundImage.title}
                className={clsx("object-cover")}
              />
              {section.theme === "dark" && (
                <div
                  className={clsx(
                    "absolute inset-0 z-20 bg-gradient-to-br from-navy to-black opacity-75",
                  )}
                ></div>
              )}
            </div>
          )}
          <div className={clsx("Background__children", "relative z-20")}>
            <PageSections sections={section.children} isFirst={false} />
          </div>
        </div>
      );

    case "group":
      return (
        <div className={clsx("")}>
          <PageSections sections={section.children} isFirst={false} />
        </div>
      );

    case "horizontalLine":
      return <HorizontalLine size={section.size} />;

    case "anchor":
      return <div id={section.anchorName}></div>;

    case "hero":
      return (
        <Hero
          breadcrumbTarget={section.breadcrumbTarget}
          backgroundImage={section.backgroundImage?.[0]}
          heading={section.heading}
          subHeading={section.subHeading}
          textAlignment={section.textAlignment}
          headingTag={section.headingTag}
          headingSize={section.headingSize}
          headline={section.headline}
          standfirst={section.standfirst}
          displayNecctonLogo={section.displayNecctonLogo}
        />
      );

    case "fullWidthImage":
      return <FullWidthImage image={section.image?.[0]} />;

    case "iconGrid":
      return <IconGrid items={section.iconGrid} columns={section.columns} />;

    case "imageGrid":
      return <ImageGrid images={section.imageGrid} />;

    default:
      return <div>{section.typeHandle}</div>;
  }
};

interface ISectionProps {
  children: ReactNode;
  typeHandle: string;
  backgroundColor: string;
}

const Section = ({
  children,
  typeHandle,
  backgroundColor = "transparent",
}: ISectionProps): JSX.Element => {
  const { ref, inView } = useInView();

  const className = clsx("PageSection", `PageSection--${typeHandle}`);

  return (
    <div
      ref={ref}
      className={className}
      style={{ backgroundColor: backgroundColor }}
    >
      <div
        className={clsx(
          "PageSection__content",
          "relative",
          "transition-opacity duration-500 ease-out",
          {
            "opacity-0": !inView,
            "opacity-100": inView,
          },
        )}
      >
        {children}
      </div>
    </div>
  );
};

const PageSections = ({ sections, isFirst = true }): JSX.Element => {
  return (
    <div
      className={clsx("PageSections", "overflow-hidden", {
        "pt-32 lg:pt-60": isFirst,
      })}
    >
      {sections?.map((section, index) => {
        return (
          <div
            key={section.id}
            className={clsx(
              "PageSections__section",
              `PageSections__section--${section.typeHandle}`,
            )}
          >
            <Section
              typeHandle={section.typeHandle}
              backgroundColor={section.backgroundColor}
            >
              <Component section={section} />
            </Section>
          </div>
        );
      })}
    </div>
  );
};

export default PageSections;
