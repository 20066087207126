import clsx from "clsx";
import Image from "next/legacy/image";

const AwardsGrid = ({ awards }) => {
  return (
    <div className={clsx("AwardsGrid", "px-16")}>
      <div className={clsx("AwardsGrid__content", "mx-auto max-w-screen-2xl")}>
        <div className={clsx("AwardsGrid__awards", "grid grid-cols-6 gap-8")}>
          {awards.map((award) => {
            const logo = award.logo[0];

            return (
              <div
                key={award.id}
                className={clsx("AwardsGrid__award", "col-span-2")}
              >
                <div
                  className={clsx(
                    "AwardsGrid__card",
                    "p-16",
                    "bg-cool-gray",
                    "h-full",
                    "rounded-xl",
                    "shadow-xl",
                    "text-center"
                  )}
                >
                  <div className={clsx("mb-8")}>
                    <div className="relative aspect-16/9">
                      <div className="flex h-full w-full items-center justify-center ">
                        <Image
                          src={logo.url}
                          layout="fill"
                          objectFit="contain"
                          draggable={false}
                          alt={logo.title}
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className={clsx("AwardsGrid__heading", "font-bold")}>
                      {award.heading}
                    </div>
                    <div>{award.subHeading}</div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default AwardsGrid;
