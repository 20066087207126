import clsx from "clsx";

const HorizontalLine = ({ size }) => {
  return (
    <div
      className={clsx("HorizontalLine", "max-auto", {
        "HorizontalLine--size--sm max-w-screen-lg px-16": size === "sm",
        "HorizontalLine--size--md max-w-screen-xl px-16": size === "md",
        "HorizontalLine--size--lg max-w-screen-2xl px-16": size === "lg",
        "HorizontalLine--size--xl w-full": size === "xl",
      })}
    >
      <div className={clsx("mx-auto")}>
        <hr className={clsx("border-t border-cool-gray")} />
      </div>
    </div>
  );
};

export default HorizontalLine;
