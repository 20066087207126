import clsx from "clsx";

import Card from "@/components/Card";

const CardGrid = ({ cards, aspectRatio = "16/11" }) => {
  let backgroundImage, image, video;

  return (
    <div className={clsx("CardGrid", "px-8 lg:px-16")}>
      <div className={clsx("CardGrid__content", "mx-auto max-w-screen-2xl")}>
        <div
          className={clsx(
            "CardGrid__cards",
            "grid grid-cols-12 gap-y-6 sm:gap-x-6",
          )}
        >
          {cards.map((card) => {
            backgroundImage = card.backgroundImage?.[0];
            image = card.image?.[0];
            video = card.video?.[0];

            return (
              <div
                key={card.id}
                className={clsx("CardGrid__card", "", {
                  "col-span-12 md:col-span-6 xl:col-span-3":
                    card.width === "oneQuarter",
                  "col-span-12 md:col-span-6 xl:col-span-4":
                    card.width === "oneThird",
                  "col-span-12 md:col-span-6 xl:col-span-6":
                    card.width === "half",
                  "col-span-12 md:col-span-6 xl:col-span-8":
                    card.width === "twoThirds",
                  "col-span-12 md:col-span-full": card.width === "full",
                  "xl:col-start-4": card.width === "half" && cards.length === 1,
                })}
              >
                <div
                  className={clsx("flex h-full shrink-0 flex-col space-y-4")}
                >
                  <div className={clsx("h-full")}>
                    <Card
                      attribution={card.attribution}
                      backgroundImage={backgroundImage}
                      eyebrow={card.eyebrow}
                      figure={card.figure}
                      gradientBackground={card.gradientBackground}
                      image={image}
                      label={card.label}
                      quotation={card.quotation}
                      target={card.target}
                      text={card.text}
                      secondaryText={card.secondaryText}
                      videoUrl={card.videoUrl}
                      width={card.width}
                      videoFile={video}
                      backgroundColor={card.backgroundColor || "white"}
                      textColor={card.textColor || "navy"}
                      border={card.border || "none"}
                      aspectRatio={card.aspectRatio || aspectRatio || "16/11"}
                      bodyText={card.bodyText}
                      logo={card.logo?.[0]}
                    />
                  </div>
                  {!!card.caption && (
                    <div
                      className={clsx(
                        "w-full text-lg font-bold md:text-xl lg:text-2xl",
                        {
                          "text-center": card.captionAlignment === "center",
                          "text-left": card.captionAlignment === "left",
                          "text-3xl": card.captionSize === "lg",
                          "text-2xl": card.captionSize === "sm",
                        },
                      )}
                      dangerouslySetInnerHTML={{ __html: card.caption }}
                    ></div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CardGrid;
