import Target from "@/components/Target";
import clsx from "clsx";
import { ArrowRight } from "react-feather";

const TextButton = ({ target }) => {
  return (
    <Target
      target={target}
      className={clsx("TextButton", "flex items-center", "group")}
    >
      <div
        className={clsx(
          "TextButton__icon",
          "text-pink",
          "transition-transform group-hover:translate-x-1"
        )}
      >
        <ArrowRight strokeWidth={2} size={20} />
      </div>
      <div
        className={clsx(
          "TextButton__label",
          "font-bold leading-none tracking-tight",
          "ml-2"
        )}
      >
        {target.text}
      </div>
    </Target>
  );
};

export default TextButton;
