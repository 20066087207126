import QuoteMark from "@/components/QuoteMark";
import clsx from "clsx";
import React from "react";
import ReactHtmlParser from "react-html-parser";

const Quotation = ({ quotation, attribution = "" }) => {
  return (
    <div className={clsx("Quotation", "relative")}>
      <span
        className={clsx(
          "Quotation__quoteMark",
          "absolute",
          "-left-7 top-1 lg:-left-12 lg:top-0",
          "h-[15px] w-[21px] lg:h-[27px] lg:w-[37px]"
        )}
      >
        <QuoteMark />
      </span>
      <div
        className={clsx(
          "Quotation__quotation",
          "max-w-5xl",
          "mb-7.5",
          "inline-flex",
          "markdown text-2xl font-bold tracking-tight md:text-3xl lg:text-4xl xl:text-5xl 2xl:text-6xl"
        )}
      >
        {ReactHtmlParser(quotation)}
      </div>
      <div
        className={clsx(
          "Quotation__attribution",
          "text-2xl font-bold tracking-tight",
          "pt-8"
        )}
      >
        {ReactHtmlParser(attribution)}
      </div>
    </div>
  );
};

export default Quotation;
