import Target from "@/components/Target";
import useRemoveTextGap from "@/hooks/useRemoveTextGap";
import necctonLogo from "@/images/powered-by-neccton.png";
import clsx from "clsx";
import Image from "next/image";
import { useRef } from "react";
import { ArrowLeft } from "react-feather";

const Hero = ({
  breadcrumbTarget = null,
  heading,
  subHeading,
  backgroundImage,
  textAlignment = "center",
  headingTag = "h1",
  headline = "",
  standfirst = "",
  displayNecctonLogo = false,
}): JSX.Element => {
  const HeadingTag = headingTag || "h1";

  const headingRef = useRef(null);
  const spanRef = useRef(null);

  // Use the custom hook
  useRemoveTextGap(headingRef);

  return (
    <div className={clsx("Hero", "relative z-0")}>
      <div
        className={clsx(
          "relative aspect-[4/3] md:aspect-[24/12] lg:aspect-[24/11]",
        )}
      >
        {!!backgroundImage && (
          <div className={clsx("absolute inset-0 z-10")}>
            <Image
              src={backgroundImage.url}
              fill
              alt={backgroundImage.title}
              className={clsx("object-cover")}
            />
            <div
              className={clsx(
                "absolute inset-0 bg-gradient-to-b from-black/30 to-black/50",
              )}
            />
          </div>
        )}

        {displayNecctonLogo && (
          <div
            className={clsx(
              "absolute right-8 top-8 z-20 flex justify-end lg:right-16 lg:top-16",
            )}
          >
            <Image
              src={necctonLogo}
              width={necctonLogo.width}
              height={necctonLogo.height}
              alt="Powered by Neccton"
              className={clsx("w-1/2 lg:w-full")}
            />
          </div>
        )}

        <div
          className={clsx(
            "relative z-20 flex h-full w-full items-center text-left text-white lg:px-16",
            {},
          )}
        >
          <div
            className={clsx("container space-y-8 px-8 lg:px-16", {
              "lg:-mt-28 xl:-mt-32": !!headline || !!standfirst,
              "justify-center": textAlignment === "center",
            })}
          >
            {textAlignment === "left" &&
              !!breadcrumbTarget &&
              !!breadcrumbTarget.url && (
                <div className={clsx("relative")}>
                  <Target
                    target={breadcrumbTarget}
                    className={clsx("group flex items-center")}
                  >
                    <div
                      className={clsx(
                        "absolute -translate-x-6 transition ease-out group-hover:-translate-x-9 lg:-translate-x-8",
                      )}
                    >
                      <ArrowLeft className={clsx("h-4 w-4 lg:h-6 lg:w-6")} />
                    </div>
                    <div
                      className={clsx(
                        "text-base font-bold sm:text-lg md:text-xl lg:text-2xl",
                      )}
                    >
                      {breadcrumbTarget.text}
                    </div>
                  </Target>
                </div>
              )}

            {!!heading && (
              <div
                className={clsx("", {
                  "flex justify-center": textAlignment === "center",
                })}
              >
                <div className={clsx("relative isolate inline-block")}>
                  <HeadingTag
                    ref={headingRef}
                    className={clsx("relative z-10 text-balance font-bold", {
                      "text-5xl md:text-6xl lg:text-8xl":
                        textAlignment === "center",
                      "text-3xl md:text-4xl lg:text-6xl":
                        textAlignment === "left",
                    })}
                  >
                    {heading}
                  </HeadingTag>
                  <div
                    ref={spanRef}
                    className={clsx(
                      "absolute left-0 z-0  w-full scale-x-100 bg-pink",
                      {
                        "-bottom-2.5 h-2 lg:-bottom-3.5 lg:h-3":
                          textAlignment === "center",
                        "-bottom-3 h-1.5 lg:-bottom-3.5 lg:h-2":
                          textAlignment === "left",
                      },
                    )}
                  />
                </div>
              </div>
            )}

            {!!subHeading && (
              <div
                dangerouslySetInnerHTML={{ __html: subHeading }}
                className={clsx(
                  "max-w-5xl text-balance text-2xl font-bold md:text-3xl lg:text-4xl xl:text-5xl",
                )}
              ></div>
            )}
          </div>
        </div>
      </div>
      {(!!headline || !!standfirst) && (
        <div className={clsx("relative z-20 lg:-mt-28 xl:-mt-32")}>
          <div>
            <div
              className={clsx(
                "mx-auto max-w-7xl space-y-4 rounded-t-3xl bg-white p-8 text-navy shadow-xl lg:p-16",
              )}
            >
              {!!headline && (
                <div
                  className={clsx(
                    "max-w-4xl text-balance text-2xl font-bold md:text-3xl lg:text-4xl",
                  )}
                >
                  {headline}
                </div>
              )}
              {!!standfirst && (
                <div
                  className={clsx(
                    "prose text-balance text-lg font-bold md:text-xl lg:text-2xl",
                  )}
                  dangerouslySetInnerHTML={{ __html: standfirst }}
                ></div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Hero;
